<template>
	<viewer-layout
		:primary-toolbar-config="{
			Back: {
				group: 'left',
				class: 'back-btn',
				icon: 'arrow-left',
				action: () => this.$router.go(-1),
			},
		}"
		:settings-config="{
			'Intersecting Line Angles': {
				hidden: true,
			},
			'Reference Lines': {
				hidden: true,
			},
		}"
	>
		<!-- <template #primary-buttons-right>
			<ast-toolbar-button
				icon="inbox-download"
				label="Reject Image"
				class="label-right"
				:class="isRejected && 'added-to-report'"
				@click.native="openRejection"
			/>
		</template> -->
		<template #right-drawer>
			<section style="padding: 12px;">
				<h4>
					<svg-icon icon="inbox-download" fixed />
					Review Images
				</h4>
				<div class="button-column">
					<ast-button>
						Reject Image
					</ast-button>
					<ast-button>
						Reject Study
					</ast-button>
					<ast-button>
						Approve
					</ast-button>
				</div>
			</section>
		</template>
	</viewer-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ViewerLayout from '@router/layouts/Viewer'
// import AstToolbarButton from '@components/ToolbarButton.vue'
import AstButton from '@components/Button'
import { openPromptDlg } from '@/dialogs/TextPromptDlg.vue'

export default {
	name: 'ReviewViewer',
	components: {
		ViewerLayout,
		AstButton,
		// AstToolbarButton,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters(['activeImage']),
		isRejected() {
			return !this.activeImage || false
		},
	},
	async mounted() {
		await Promise.all([this.getConsignerStudyViewerVmAsync({ id: this.id }), this.getHangingProtocols()])
		await this.getUserViewerSettings()
	},
	methods: {
		...mapActions(['getConsignerStudyViewerVmAsync', 'getUserViewerSettings', 'getHangingProtocols']),
		async openRejection() {
			// FIXME: needs to have both a Reject Active Image and Reject All Images
			const response = await openPromptDlg({
				buttonLabel: 'Reject',
				title: `Reject Images for ${this.translate('consignerStudy')} 0000`,
				prompt: 'Provide a reason for rejecting this images',
				requireInput: true,
			})
			if (response) {
				this.$router.go(-1)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
h4 {
	width: 176px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	font-weight: normal;
	margin-bottom: 1em;
}
.button-column {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
</style>
