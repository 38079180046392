var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('viewer-layout',{attrs:{"primary-toolbar-config":{
		Back: {
			group: 'left',
			class: 'back-btn',
			icon: 'arrow-left',
			action: function () { return this$1.$router.go(-1); },
		},
	},"settings-config":{
		'Intersecting Line Angles': {
			hidden: true,
		},
		'Reference Lines': {
			hidden: true,
		},
	}},scopedSlots:_vm._u([{key:"right-drawer",fn:function(){return [_c('section',{staticStyle:{"padding":"12px"}},[_c('h4',[_c('svg-icon',{attrs:{"icon":"inbox-download","fixed":""}}),_vm._v("\n\t\t\t\tReview Images\n\t\t\t")],1),_c('div',{staticClass:"button-column"},[_c('ast-button',[_vm._v("\n\t\t\t\t\tReject Image\n\t\t\t\t")]),_c('ast-button',[_vm._v("\n\t\t\t\t\tReject Study\n\t\t\t\t")]),_c('ast-button',[_vm._v("\n\t\t\t\t\tApprove\n\t\t\t\t")])],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }